<template>
  <div class="login" style=" color: #000">
    <van-nav-bar title="登录" left-arrow :fixed="true" :z-index="999"></van-nav-bar>
    同行登录

  </div>
</template>
<script>
import {  } from "@/api/tonghang";
// import moment from "moment";
export default {
  data() {
    return {

    }
  },
  mounted(){},
  methods: {
    handleResize() {
      if (window.innerHeight < this.originalHeight) {
        this.isKeyboardOpen = true;
      } else {
        this.isKeyboardOpen = false;
      }
    },
    onFocus() {
      // 可以在这里添加额外的逻辑
      this.isKeyboardOpen = true;
    },
    onBlur() {
      // 可以在这里添加额外的逻辑
      this.isKeyboardOpen = false;
    },
    getSearchString(key, Url) {
      var str = Url;
      str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      return obj[key];
    },
    initData(){
      axios.get(`${baseURL}/vehicle/open/api/shard/order/garage/info`, {
        params: {
          gid: this.gid
        },
      })
      .then((res) => {
        if (res.data.code == 200) {
          this.imgUrl = res.data.data.doorPic
          this.gname = res.data.data.name
          document.title = this.gname
        }
      })
    },
    confirmFun(){
      if(this.psd == ''){
        this.$toast("请输入密码");
        return
      }
      axios.get(`${baseURL}/vehicle/open/api/shard/order/video/check`, {
        params: {
          oid: this.oid,
          passWord:this.psd,
        }
      }).then((res) => {
        if (res.data.code == 200) {
          if(res.data.data == true){
            window.location.href = `gwdetail.html?gid=${this.gid}&oid=${this.oid}&code=${this.code}&name=${this.gname}`;
          }else{
            this.$toast("密码错误！");
            return
          }
        }else{
          this.$toast("密码错误！");
          return
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login{

}
</style>